import "./Home.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import React, { useState } from "react";

const Home = () => {
  const [showIframe, setShowIframe] = useState<boolean>(false);
  const [iframeUrl, setIframeUrl] = useState<string>("");

  return (
    <section className="comingsoon_content">
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        showThumbs={false}
        className="hero_banners"
      >
        <div
          className="hero_banner"
          style={{ backgroundImage: `url(/assets/img/hero1.jpg)` }}
        ></div>
        <div
          className="hero_banner"
          style={{ backgroundImage: `url(/assets/img/hero2.jpg)` }}
        ></div>
        <div
          className="hero_banner"
          style={{ backgroundImage: `url(/assets/img/hero3.jpg)` }}
        ></div>
        <div
          className="hero_banner"
          style={{ backgroundImage: `url(/assets/img/hero4.png)` }}
        ></div>
        <div
          className="hero_banner"
          style={{ backgroundImage: `url(/assets/img/hero5.png)` }}
        ></div>
      </Carousel>

      <div
        id="iframe"
        className={`fadeInAndOut ${showIframe ? "fadeIn" : "fadeOut"}`}
        onClick={() => {
          setShowIframe(false);
          setIframeUrl("");
        }}
      >
        <div className="close">x</div>
        <iframe src={iframeUrl}></iframe>
      </div>

      <div className="sidebar">
        <div className="text landing">
          <img className="logo" src="/assets/img/logo.png" />
          <h1>Mama Bella is open!</h1>
          <p>Where Hibiscus and S. Dixie Hwy Meet</p>
          <div className="times">
            <p>9am-8pm Mon-Sat</p>
            <p>9am-6pm Sun</p>
          </div>
          <button
            onClick={() => {
              setIframeUrl(
                "https://online.skytab.com/1c00ca4060fe13afaf0914b97e3de256"
              );
              setShowIframe(true);
            }}
            className="button order_online"
          >
            Order Online Now
          </button>
          <div className="menus">
            <p>Our Menus</p>
            <div
              className="menu"
              onClick={() => {
                setIframeUrl("https://mamabella-menu.web.app/menu/kitchen-tv");
                setShowIframe(true);
              }}
            >
              Breakfast
            </div>
            <div
              className="menu"
              onClick={() => {
                setIframeUrl(
                  "https://mamabella-menu.web.app/menu/coffee-station"
                );
                setShowIframe(true);
              }}
            >
              Coffee and Smoothies
            </div>
            <div
              className="menu"
              onClick={() => {
                setIframeUrl(
                  "https://mamabella-menu.web.app/menu/wine-station"
                );
                setShowIframe(true);
              }}
            >
              Wine Tower
            </div>
            <div
              className="menu"
              onClick={() => {
                setIframeUrl(
                  "https://mamabella-menu.web.app/menu/menu-left-side"
                );
                setShowIframe(true);
              }}
            >
              Subs & Sandwiches
            </div>
            <div
              className="menu"
              onClick={() => {
                setIframeUrl("https://mamabella-menu.web.app/menu/pizza-menu");
                setShowIframe(true);
              }}
            >
              Pizza
            </div>
            <div
              className="menu"
              onClick={() => {
                setIframeUrl("https://mamabella-menu.web.app/menu/dinner-menu");
                setShowIframe(true);
              }}
            >
              Bar
            </div>
          </div>
          <div className="socialMedia">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/MamaBellaGourmetMarket">
              <img src="/assets/img/fb.png" alt="Facebook" />

            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/mamabellagourmetmarket/">
              <img src="/assets/img/insta.png" alt="Instagram" />
            </a>

            <a target="_blank" rel="noreferrer" href="http://menus.fyi/4899568">
              <img src="/assets/img/grubhub.svg" alt="Facebook" />

            </a>
            <a target="_blank" rel="noreferrer" href="https://order.online/business/mama-bella-cafe-11356308">
              <img src="/assets/img/doordash.svg" alt="Instagram" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.ubereats.com/store/mama-bella/HPZw_OLmQSe_sEngpBr-lQ?utm_source=wok">
              <img src="/assets/img/ubereats.svg" alt="Instagram" />
            </a>


          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
