import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";

import NavBar from "./components/NavBar";
import Layout from "./components/Layout";

ReactDOM.render(
  <Router>
    <NavBar />
    <Layout>
      <App />
    </Layout>
  </Router>,
  document.getElementById("root")
);
