import style from "./Layout.module.scss";
import React, { useState, useEffect } from "react";

const Layout: React.FC = ({ children }) => {
  return (
    <>
      <div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default Layout;
